import React from 'react';
import { Link } from 'gatsby';
import SEO from '../components/SEO';

const NotFoundPage = () => (
  <>
    <SEO title="404: Nie znaleziono" />
    <main>
      <h2>404: Nie znaleziono</h2>
      <Link to="/">
        <p>szumstepuipustyni.pl</p>
      </Link>
    </main>
  </>
);

export default NotFoundPage;
